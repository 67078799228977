import {GET_PROSJEKT, RESET_PROSJEKT_STATE} from "../actions/Action_Prosjekt";
import {Prosjekt} from "../models/Prosjekt";

interface IProsjektState {
    prosjekt:Prosjekt;
}

const initialProsjektState:IProsjektState = {
    prosjekt: null
};

export default (state = initialProsjektState, action) => {

    switch (action.type){

        case GET_PROSJEKT:

            return {...state, prosjekt: action.payload};

        case RESET_PROSJEKT_STATE:

            return initialProsjektState;

        default:
            return state;
    }
}