import {combineReducers} from "redux";
import {reducer as FormReducer} from 'redux-form';

import FDVReducer from './../reducers/Reducer_FDV';
import AuthReducer from './../reducers/Reducer_Auth';
import ProsjektReducer from './../reducers/Reducer_Prosjekt';
import ReklamasjonReducer from './../reducers/Reducer_Reklamasjon';

const rootReducer = combineReducers({
    FDV: FDVReducer,
    auth: AuthReducer,
    prosjekt: ProsjektReducer,
    reklamasjon: ReklamasjonReducer,
    form: FormReducer,
});

export default rootReducer;