import {CREATE_REKLAMASJON, GET_REKLAMASJONER} from "../actions/Action_Reklamasjon";
import {mapReklamasjonerResponseToObjects} from "../utils/ReklamasjonUtils";
import {Reklamasjon} from "../models/Reklamasjon";


interface IReklamasjonState{
    reklamasjoner:Reklamasjon[];
}

const initialReklamasjonState:IReklamasjonState = {
    reklamasjoner:[]
};


export default (state = initialReklamasjonState, action) => {

    switch (action.type){

        case GET_REKLAMASJONER:

            return {...state, reklamasjoner: mapReklamasjonerResponseToObjects(action.payload)};

        case CREATE_REKLAMASJON:

            //const createdReklamasjon:Reklamasjon = mapReklamasjonerResponseToObjects([action.payload])[0];

            //return {...state, reklamasjoner: {...createdReklamasjon}};
            return state;

        default:
            return state;
    }
}