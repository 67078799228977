import * as React from 'react';

import './PageViewContainer.scss';

export interface IPageViewContainerProps{
    title?:string;
}

class PageViewContainer extends React.Component<IPageViewContainerProps>{

    constructor(props){
        super(props);

        window.scrollTo(0,0);
    }

    renderTitle(){
        const {title} = this.props

        if (title){
            return <h2>{title}</h2>
        }
    }

    render(){
        return(
            <div className="page-view-container ms-Grid ms-md12">
                {this.renderTitle()}
                {this.props.children}
            </div>
        )
    }
}

export default PageViewContainer;