import * as jwt_decode from 'jwt-decode';

export function tokenExpired():boolean{

    const token = localStorage.getItem('token');

    // Hvis token ikke er satt vil en ProtectedRoute bestandig være utilgjengelig
    if (!token){
        return true;
    }

    // Sammenlikner dagens dato med tokenets utløpsdato
    return (jwt_decode(token).exp < Date.now() / 1000);
}