import {API_CODE, BASE_API_URL} from "./Actions";
import axios from "axios";
import {FileItem} from "../models/FileItem";

export const GET_FDV_STRUCTURE:string = "GET_FDV_STRUCTURE";
export const DOWNLOAD_SINGLE_FILE:string = 'DOWNLOAD_SINGLE_FILE';
export const RESET_FDV_STATE:string = "RESET_FDV_STATE";

export function getFolderStructure() {

    return new Promise((resolve, reject) => {
        const request = axios.get(`${BASE_API_URL}/FDV/structure${API_CODE}`, {
            headers:{
                Authorization: localStorage.getItem('token')
            }
        }).then(response=>{

            resolve({
                type: GET_FDV_STRUCTURE,
                payload: response.data
            });
        }).catch(error=>{

            resolve({
                type: GET_FDV_STRUCTURE,
                payload: null
            });
        });
    });
}

export function downloadSingleFile(fileItem:FileItem) {

    return new Promise((resolve, reject) => {
        const request = axios.get(`${BASE_API_URL}/FDV/download_file${API_CODE}`, {
            headers:{
                Authorization: localStorage.getItem('token')
            }
        }).then(response=>{

            resolve({
                type: DOWNLOAD_SINGLE_FILE,
                payload: response.data
            });
        }).catch(error=>{

            resolve({
                type: DOWNLOAD_SINGLE_FILE,
                payload: null
            });
        });
    });
}

export function resetFDVState() {
    return{
        type: RESET_FDV_STATE,
        payload: null
    }
}