import {AUTH_CREATE_FAILURE, AUTH_CREATE_SUCCESS, AUTH_LOGIN_FAILURE, AUTH_LOGIN_SUCCESS} from "../actions/Action_Auth";

interface IAuthState{
    jwt:string;
    error:string;
    errorPassword:string;
}

const initialAuthState:IAuthState = {
    jwt:null,
    error:'',
    errorPassword: ''
};

export default (state = initialAuthState, action) => {

    switch (action.type) {
        case AUTH_LOGIN_SUCCESS:

            return {...state, jwt: action.payload.jwt, error: '', errorPassword: ''};

        case AUTH_LOGIN_FAILURE:

            return {...state, error: action.payload, errorPassword: ''};

        case AUTH_CREATE_FAILURE:

            return {...state, errorPassword: action.payload};

        case AUTH_CREATE_SUCCESS:

            return {...state, errorPassword: ''};

        default:
            return state;
    }
}