import * as React from 'react';
import {Icon, IconButton} from "office-ui-fabric-react";
import {AttachmentFile} from "../../models/AttachmentFile";

interface IFileListItemProps{
    file:AttachmentFile;
    onSelected:()=>void;
    onRemoved:()=>void;
}

class FileListItem extends React.Component<IFileListItemProps>{

    constructor(props: Readonly<IFileListItemProps>) {
        super(props);
    }

    render(){

        const {file} = this.props;

        return(
            <div onClick={()=>this.props.onRemoved()} className="ms-Grid-col file-list-item ms-sm12 p-y-5">
                <div style={{fontWeight: 600}}>{file.name}</div>
                <div>
                    <button type="button">
                        <Icon iconName="Cancel" />
                    </button>
                </div>
            </div>
        )
    }
}

export default FileListItem;