import * as React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import PageViewContainer from "../common/PageViewContainer";
import PageViewHeader from "../common/PageViewHeader";
import ChangePasswordForm from "./ChangePasswordForm";
import {Prosjekt} from "../../models/Prosjekt";
import {getProsjekt} from "../../actions/Action_Prosjekt";

interface IChangePasswordViewProps{
    prosjekt:Prosjekt;
    history:any;

    getProsjekt();
}

interface IChangePasswordViewState {

}

class ChangePasswordView extends React.Component<IChangePasswordViewProps, IChangePasswordViewState>{

    constructor(props: Readonly<IChangePasswordViewProps>) {
        super(props);
    }

    componentWillMount(){

        if (!this.props.prosjekt){
            this.props.getProsjekt().then(response=>{

            });
        }
    }

    renderProsjektColumn(){

        if (this.props.prosjekt){

            const {Title, Description, Image} = this.props.prosjekt;

            return(
                <div>
                    <img style={{maxWidth: '100%'}} src={Image} />
                    <h3>{Title}</h3>
                    <p>{Description}</p>
                </div>
            )
        }
    }

    render(){
        return(
            <PageViewContainer>
                <PageViewHeader
                    title="Endre Passord"
                    showBackButton={true}
                    onBackButtonClicked={()=>this.props.history.push('/min-side')}
                />
                <div className="ms-Grid-col ms-sm12 ms-lg4 ms-hiddenSm">
                    {this.renderProsjektColumn()}
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg8">
                    <div style={{maxWidth: '400px'}}>
                        <ChangePasswordForm onPasswordChanged={()=>this.props.history.push('/min-side')} />
                    </div>
                </div>
            </PageViewContainer>
        )
    }
}

const mapStateToProps = (state, ownProps:IChangePasswordViewProps) =>{
    return{
        prosjekt: state.prosjekt.prosjekt
    }
};

export default connect(mapStateToProps, {getProsjekt})(ChangePasswordView);