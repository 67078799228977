import {Reklamasjon} from "../models/Reklamasjon";

export function mapReklamasjonerResponseToObjects(reklamasjoner:any[]):Reklamasjon[] {

    for (let i=0; i<reklamasjoner.length; i++){

        if (reklamasjoner[i].fields){
            reklamasjoner[i] = reklamasjoner[i].fields;
        }

        reklamasjoner[i].id = parseInt(reklamasjoner[i].id);

        if (reklamasjoner[i].Dato_Avsluttet && reklamasjoner[i].Dato_Avsluttet.startsWith('0001')){
            reklamasjoner[i].Dato_Avsluttet = null;
        }
    }
    return reklamasjoner;
}

export function getStatusColor(status:string):string {

    if(!status){
        return "lightgrey";
    }

    switch (status.toLowerCase()){

        case "ferdig":
            return "#28a745";

        case "pågår":
            return "#ffc107";

        case "mottatt":
            return "#dc3545";

        default:
            return "#17a2b8";

    }

}