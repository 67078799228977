import * as React from 'react';
import {withRouter, Switch, Route} from 'react-router-dom';
import Index from "../index";
import LoginView from "../login/LoginView";
import MinSide from "../min_side/MinSide";
import ReklamasjonCreateView from "../reklamasjon/ReklamasjonCreateView";
import ProtectedRoute from "./ProtectedRoute";
import ReklamasjonListView from "../reklamasjon/ReklamasjonListView";
import FDVView from "../FDV/FDVView";
import ChangePasswordView from "../change_password/ChangePasswordView";


interface IRoutesProps {
    location:any;
}


class Routes extends React.Component {

    constructor(props: Readonly<IRoutesProps>) {
        super(props);

        window.scrollTo(0,0);
    }

    componentDidUpdate(props){
        // Scrolls to top if route has changed
        /*
        if (this.props.location != props.location){
            window.scrollTo(0,0);
        }
        */
    }

    render(){
        return(
            <Switch>
                <ProtectedRoute path="/FDV" component={FDVView}/>
                <ProtectedRoute path="/ny_sak" component={ReklamasjonCreateView}/>
                <ProtectedRoute path="/endre_passord" component={ChangePasswordView}/>
                <ProtectedRoute path="/reklamasjonsoversikt" component={ReklamasjonListView}/>
                <ProtectedRoute path="/min-side" exact component={MinSide}/>
                <Route path="/login" exact component={LoginView}/>
                <Route path="/" exact component={Index}/>
            </Switch>
        )
    }
}

export default Routes;