import * as React from 'react';

import './RutaButton.scss';
import {Icon} from "office-ui-fabric-react";

interface IRutaButtonProps{
    title:string;
    description:string;
    color:string;
    onButtonClicked:()=>void;
}

class RutaButton extends React.Component<IRutaButtonProps>{

    constructor(props:IRutaButtonProps) {
        super(props);
    }

    render(){

        const {title, description, color, onButtonClicked} = this.props;
        return(
            <div className="ruta-button-container">
                <button onClick={()=>onButtonClicked()} className="ruta-button ms-textAlignLeft"style={{border: `2px solid ${color}`}}>
                    <h2>{title}</h2>
                    {/*
                    <hr style={{borderBottom: `5px solid ${color}`}}/>
                    */}
                    <div className="description-row">
                        <p style={{color: color}}>{description}</p>
                        <Icon iconName="ChevronRight" style={{color: color}} />
                    </div>
                </button>
            </div>
        )
    }
}

export default RutaButton;