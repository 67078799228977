import {IDatePickerStrings} from "office-ui-fabric-react";


const datePickerStrings:IDatePickerStrings = {
    months: [
        'Januar',
        'Februar',
        'Mars',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Desember'
    ],

    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'],

    days: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],

    shortDays: ['sø', 'ma', 'ti', 'on', 'to', 'fr', 'lø'],

    goToToday: 'Gå til idag',
    prevMonthAriaLabel: 'Gå til forrige måned',
    nextMonthAriaLabel: 'Gå til neste måned',
    prevYearAriaLabel: 'Gå til forrige år',
    nextYearAriaLabel: 'Gå til neste år',

    isRequiredErrorMessage: 'Dette feltet er påkrevd.',

    invalidInputErrorMessage: 'Ugyldig dato-format.'
};

export function getDatePickerStrings() {
    return datePickerStrings;
}