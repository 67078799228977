import * as React from 'react';
import * as jwt_decode from 'jwt-decode';
import PageViewContainer from "../common/PageViewContainer";
import ReklamasjonForm from "./ReklamasjonForm";
import PageViewHeader from "../common/PageViewHeader";

interface IReklamasjonCreateViewProps {
    history:any;
}

interface IReklamasjonCreateViewState{
    reklamasjon:any;
}

class ReklamasjonCreateView extends React.Component<IReklamasjonCreateViewProps, IReklamasjonCreateViewState>{

    constructor(props: Readonly<IReklamasjonCreateViewProps>) {
        super(props);

        const token = localStorage.getItem('token');

        const Leilighet:string = jwt_decode(token).sub;

        this.state = {
            reklamasjon: {Leilighet: Leilighet, Dato_Innsendt: new Date()},
        };
    }

    render(){
        return(
            <PageViewContainer>
                <PageViewHeader
                    title="Ny Reklamasjon"
                    showBackButton={true}
                    onBackButtonClicked={()=>this.props.history.push('/reklamasjonsoversikt')}
                />
                {this.state.reklamasjon &&
                    <ReklamasjonForm
                        initialValues={this.state.reklamasjon}
                        onCreateSuccess={()=>this.props.history.push('/reklamasjonsoversikt')}
                    />
                }
            </PageViewContainer>
        )
    }
}

export default ReklamasjonCreateView;