import * as React from 'react';
import {connect} from 'react-redux';

import './AttachmentFiles.scss';
import FileAddButton from "./FileAddButton";
import FileListItem from './FileListItem';

interface IAttachmentFilesProps {
    files:any[];
    onRemoveFile:(file:any, index:number)=>void;
    onAddFiles:(event)=>void;
}

interface IAttachmentFilesState {
    showDialog:boolean;
    selectedFileIndex:number;
}

class AttachmentFiles extends React.Component<IAttachmentFilesProps, IAttachmentFilesState>{

    constructor(props:Readonly<IAttachmentFilesProps>) {
        super(props);

        this.state = {
            showDialog: false,
            selectedFileIndex: 0
        };

        this.onFileSelected = this.onFileSelected.bind(this);
    }

    /****************************
            Event handlers
     ****************************/

    onFileSelected(image:any, index:number){
        this.setState({
            selectedFileIndex: index,
            showDialog: true
        });
    }

    /****************************
            Render methods
     ****************************/

    renderFileList(){
        return this.props.files.map((file, index)=>{
            return(
                <FileListItem
                    file={file}
                    onSelected={()=>this.onFileSelected(file, index)}
                    onRemoved={()=>this.props.onRemoveFile(file, index)}
                />
            )
        })
    }

    render(){
        return(
            <div>
                {this.renderFileList()}

                <FileAddButton
                    onAddFiles={(event)=>this.props.onAddFiles(event)}
                />

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return{

    }
};

export default connect(mapStateToProps, {

})(AttachmentFiles);