import * as React from 'react';

import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {browserHistory} from 'react-router';


const logo = require('../../assets/img/logo_small.png');

import './Navbar.scss';
import {ActionButton} from "office-ui-fabric-react";
import {resetProsjektState} from "../../actions/Action_Prosjekt";
import {resetFDVState} from "../../actions/Action_FDV";
import {resetReklamasjonState} from "../../actions/Action_Reklamasjon";

export interface INavbarProps {
    isAuthenticated:boolean;

    // Actions
    resetProsjektState: () => void;
    resetFDVState: () => void;
    resetReklamasjonState: () => void;

    // Router
    history:any;
    location:any;
    routes:any;
}

export interface INavbarState {

}


class Navbar extends React.Component<INavbarProps, INavbarState>{

    constructor(props:Readonly<INavbarProps>){
        super(props);
    }

    pathActive(path:string):boolean{
        return this.props.location.pathname == path;
    }

    /****************************
            Event handlers
     ****************************/

    onLogoClicked(){
        if(this.props.location.pathname != '/'){
            this.props.history.push('');
        }
    }

    onLogout(){
        localStorage.removeItem('token');
        this.props.history.push('/');
        this.props.resetProsjektState();
        this.props.resetFDVState();
        this.props.resetReklamasjonState();

    }

    /****************************
            Render methods
     ****************************/

    renderHeaderLinks(){

        if (this.props.isAuthenticated){
            return(
                <div className="ms-Grid-row vertical-align header-links">
                    <div className={`ms-Grid-col p-x-5 nav-link nav-link-blue ${this.pathActive('/min-side') ? 'active':''}`}>
                        <Link to="/min-side">
                            <ActionButton
                                data-automation-id="test"
                                iconProps={{ iconName: 'Home' }}
                            >
                                Min Side
                            </ActionButton>
                        </Link>
                    </div>
                    <div className={`ms-Grid-col p-x-5 nav-link nav-link-green ${this.pathActive('/endre_passord') ? 'active':''}`}>
                        <Link to="/endre_passord">
                            <ActionButton
                                data-automation-id="test"
                                iconProps={{ iconName: 'Unlock' }}
                            >
                                Endre passord
                            </ActionButton>
                        </Link>
                    </div>
                    <div className={`ms-Grid-col p-x-5 nav-link nav-link-orange`}>
                        <ActionButton
                            iconProps={{ iconName: 'SignOut'}}
                            onClick={()=>this.onLogout()}>
                            Logg ut
                        </ActionButton>
                    </div>
                </div>
            )
        }

        if (this.props.location.pathname != '/login'){
            return(
                <div className="ms-Grid-row" style={{float: 'right'}}>
                    <div className="ms-Grid-col p-x-5">
                        <Link to="/login">Logg inn</Link>
                    </div>
                </div>
            )
        }
    }

    render(){

        if (this.props.location.pathname == '/'){
            return(<div></div>)
        }

        return(
            <div>
                <div className="ms-Grid header-container p-y-5">
                    <div className="ms-Grid-row m-x-0 p-x-0 ms-sm12">
                        <div className="ms-Grid-col ms-sm12 ms-lg6">
                            <img src={logo}
                                 tabIndex={0}
                                 style={{maxWidth: '100%'}}
                                 onClick={this.onLogoClicked.bind(this)}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-lg6 ms-textAlignRight">
                            {this.renderHeaderLinks()}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return{
        isAuthenticated: localStorage.getItem('token') ? true : false
    }
};

export default withRouter(connect(mapStateToProps, {resetProsjektState, resetFDVState, resetReklamasjonState})(Navbar));