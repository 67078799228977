import * as React from 'react';
import {connect} from 'react-redux';

import {Field, reduxForm, change} from 'redux-form';

import {login} from "../../actions/Action_Auth";
import {DefaultButton, TextField} from "office-ui-fabric-react";

interface ILoginFormProps{
    initialValues:any;
    error:string;

    onLoginSuccess:()=>void;

    // Redux form
    handleSubmit:any;
    change(field:string, value:any);

    // Requests
    login:(username:string, password:string, callback:(token:string)=>void)=>void;
}

interface ILoginFormState{
    isAwaitingLoginResponse:boolean;
}

class LoginForm extends React.Component<ILoginFormProps, ILoginFormState>{

    constructor(props:Readonly<ILoginFormProps>) {
        super(props);

        this.state = {
            isAwaitingLoginResponse: false
        }
    }

    onFormSubmit(values){

        const {username, password} = values;

        this.setState({
            isAwaitingLoginResponse: true
        });

        this.props.login(username, password, token=>{
            this.setState({
                isAwaitingLoginResponse: false
            });

            if (token){
                // Route
                localStorage.setItem('token', token);
                this.props.onLoginSuccess();
            }
        });
    };


    /****************************
            Render methods
     ****************************/

    renderInputField(field) {

        const {
            label,
            input,
            placeholder,
            required,
            isPassword,
            meta: {
                touched,
                error
            }
        } = field;

        return (
            <div className="ms-Grid-row">
                <label>{label}</label>
                <TextField
                    {...input}
                    errorMessage={touched ? error : ''}
                    placeholder={placeholder}
                    required={required ? required : false}
                    type={isPassword ? 'password': 'text'}
                />
            </div>
        );
    };


    render(){

        const {handleSubmit} = this.props;

        return(
            <form onSubmit={handleSubmit(this.onFormSubmit)} className="ms-Grid p-x-5">
                <div className="ms-Grid-col ms-sm12">
                    <Field
                        name="username"
                        label="Brukernavn"   // Custom fields variables can be fetched in component (i.e. field.label)
                        placeholder="Skriv brukernavn her ..."
                        required={true}
                        component={this.renderInputField}
                    />
                    <Field
                        name="password"
                        label="Passord"   // Custom fields variables can be fetched in component (i.e. field.label)
                        placeholder="Skriv passord her ..."
                        required={true}
                        isPassword={true}
                        component={this.renderInputField}
                    />
                    <p style={{color: 'red'}}>{this.props.error}</p>
                    <div className="ms-Grid-row ms-textAlignRight">
                        <DefaultButton
                            primary={true}
                            disabled={this.state.isAwaitingLoginResponse}
                            text="Logg inn"
                            onClick={handleSubmit(this.onFormSubmit.bind(this))}
                        />
                    </div>
                </div>
            </form>
        )
    }
}

function validate(values){
    const errors:any = {};

    if (!values.username){
        errors.username = 'Vennligst skriv inn brukernavn';
    }

    if (!values.password){
        errors.password = 'Vennligst skriv inn passord';
    }

    if (values.password && values.password.length < 8){
        errors.password = 'Passordet må inneholde minst 8 tegn';
    }

    return errors;
};

const mapStateToProps = (state, ownProps) => {
    return{
        error: state.auth.error
    }
};

export default reduxForm({
    form: 'LoginForm',
    validate,
    enableReinitialize: true,
    onSubmitFail: ()=>{
        // If form submission fails, we scroll to the top of the form
        window.scrollTo(0,0);
    },
})(connect(mapStateToProps, {login})(LoginForm));