import * as React from 'react';
import * as moment from 'moment';
import {connect} from 'react-redux';

import {Field, reduxForm, change} from 'redux-form';

import {DatePicker, DayOfWeek, DefaultButton, IDatePickerStrings, TextField} from "office-ui-fabric-react";
import {getDatePickerStrings} from "../../utils/DateUtils";
import {Reklamasjon} from "../../models/Reklamasjon";
import {createReklamasjon, uploadReklamasjonAttachment} from "../../actions/Action_Reklamasjon";
import AttachmentFiles from "../image_browser/AttachmentFiles";
import {AttachmentFile} from "../../models/AttachmentFile";
import {mapReklamasjonerResponseToObjects} from "../../utils/ReklamasjonUtils";
import {LoadingDialog} from "../loading/LoadingDialog";
import {toast} from "react-toastify";

interface IReklamasjonFormProps{
    initialValues:any;
    error:string;

    onCreateSuccess:()=>void;

    // Redux form
    handleSubmit:any;
    change(field:string, value:any);

    // Requests
    createReklamasjon(reklamasjon:Reklamasjon, callback:(ReklamasjonID:string)=>void);
    uploadReklamasjonAttachment(file:AttachmentFile, ReklamasjonID:string);
}

interface IReklamasjonFormState{
    files:AttachmentFile[];
    isAwaitingResponse:boolean;
    requestStatusText:string;
}

class ReklamasjonForm extends React.Component<IReklamasjonFormProps, IReklamasjonFormState>{

    constructor(props:Readonly<IReklamasjonFormProps>) {
        super(props);

        this.state = {
            isAwaitingResponse: false,
            files:[],
            requestStatusText: "Oppretter reklamasjon"
        }
    }

    onFormSubmit(values){

        this.setState({isAwaitingResponse: true});

        this.props.createReklamasjon(values, ReklamasjonID=>{

            // Dersom innsendingen var vellykket
            if (ReklamasjonID){

                if (!this.state.files.length){
                    toast("✓ Saken har blitt opprettet", {type: toast.TYPE.SUCCESS});
                    this.props.onCreateSuccess();
                    return;
                }

                this.setState({requestStatusText: "Laster opp vedlegg"});

                // Uploads all attachments asynchronously.
                const uploadFiles = this.state.files.map(async (file:AttachmentFile)=>{
                    return this.props.uploadReklamasjonAttachment(file, ReklamasjonID).then(response=>{
                        return response;
                    })
                });

                // Proceeds when all attachments are uploaded
                Promise.all(uploadFiles).then(vedlegg=>{
                    toast("✓ Saken har blitt opprettet", {type: toast.TYPE.SUCCESS});
                    this.props.onCreateSuccess();
                });

            } else{
                // TODO: handle error
            }
        });
    };

    onFileInputChanged(fieldName:string, files){

        let newFiles:AttachmentFile[] = [];

        for (let i = 0; i<Object.keys(files).length; i++){

            const file = files[i];

            const newFile:AttachmentFile = {
                name: file.name,
                file: file,
                type: file.type
            };

            newFiles.push(newFile);
        }

        this.setState({
            files: this.state.files.concat(newFiles)
        });
    }

    onRemoveImage(fieldName:string, images:any, index:number){

        let files = this.state.files;
        files.splice(index, 1);

        this.setState({files: files});
    }


    /****************************
            Render methods
     ****************************/

    renderInputField(field) {

        const {
            label,
            input,
            placeholder,
            required,
            disabled,
            isPassword,
            meta: {
                touched,
                error
            }
        } = field;

        return (
            <div className="ms-Grid-row">
                <label>{label}</label>
                <TextField
                    {...input}
                    errorMessage={touched ? error : ''}
                    placeholder={placeholder}
                    disabled={disabled || false}
                    required={required ? required : false}
                    type={isPassword ? 'password': 'text'}
                />
            </div>
        );
    };

    renderTextAreaField(field) {

        const {
            label,
            input,
            placeholder,
            required,
            meta: {
                touched,
                error
            }
        } = field;

        return (
            <div className="ms-Grid-row">
                <label>{label}</label>
                <TextField
                    {...input}
                    errorMessage={touched ? error : ''}
                    placeholder={placeholder}
                    multiline
                    rows={10}
                    required={required ? required : false}
                />
            </div>
        );
    };

    renderDateField(field){
        const {
            label,
            input,
            placeholder,
            required,
            meta: {
                active,
                error,
                touched,
            }
        } = field;

        const DatePickerStrings: IDatePickerStrings = getDatePickerStrings();

        DatePickerStrings.isRequiredErrorMessage = error;

        const formContainerClass = `${required ? '' : ''} ${(touched && error) ? 'form-error': ''}`;

        return(
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-lg4 p-x-0">
                    <div className={formContainerClass}>
                        <label>{label}</label>
                        <DatePicker
                            {...input}
                            isRequired={required}
                            placeholder={placeholder}
                            strings={DatePickerStrings}
                            firstDayOfWeek={DayOfWeek.Monday}
                            onSelectDate={(date)=> {this.props.change(input.name, date)}}
                            formatDate={(date)=> moment(date).format('DD.MM.YYYY')}
                        />
                        {/*
                        <p hidden={!touched && !error} className="form-error-message">
                            {(touched && !active) ? error : ''}
                        </p>
                        */}
                    </div>
                </div>
            </div>
        )
    }


    render(){

        const {handleSubmit} = this.props;

        const buttonClass = `big-button--inverse ${(this.state.isAwaitingResponse) ? 'disabled':''}`;

        return(
            <form onSubmit={handleSubmit(this.onFormSubmit)} className="ms-Grid p-x-5">
                <div className="ms-Grid-col ms-sm12">
                    <Field
                        name="Title"
                        label="Innmelder"
                        placeholder=""
                        required={true}
                        component={this.renderInputField}
                    />
                    <Field
                        name="Leilighet"
                        label="Leil / Seksjonsnr."
                        placeholder=""
                        required={true}
                        disabled={true}
                        component={this.renderInputField}
                    />
                    <Field
                        name="Mobil"
                        label="Mobil"
                        placeholder=""
                        required={true}
                        component={this.renderInputField}
                    />
                    <Field
                        name="Mail"
                        label="Mail"
                        placeholder=""
                        required={true}
                        component={this.renderInputField}
                    />
                    {/*
                    <Field
                        name="Dato_Innsendt"
                        label="Dato"
                        placeholder="Velg dato"
                        required={true}
                        component={this.renderDateField.bind(this)}
                    />
                    */}
                    <Field
                        name="Emne"
                        label="Emne"
                        placeholder=""
                        required={true}
                        component={this.renderInputField}
                    />
                    <Field
                        name="Rom"
                        label="Rom / Plassering"
                        placeholder=""
                        required={true}
                        component={this.renderInputField}
                    />
                    <Field
                        name="Beskrivelse"
                        label="Beskrivelse feil / mangel"
                        placeholder="Skriv en beskrivelse av feil / mangel"
                        component={this.renderTextAreaField}
                    />
                    <div className="ms-Grid-row">
                        <label>Vedlegg og Bilder</label>
                        <div className="ms-Grid-col ms-sm12 p-y-0">
                            <div className="ms-Grid-col ms-sm12 p-y-0">
                                <AttachmentFiles
                                    files={this.state.files}
                                    onAddFiles={(event)=> {this.onFileInputChanged("images", event.target.files)}}
                                    onRemoveFile={(image:any, index:number)=>{
                                        this.onRemoveImage("images", image, index);
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <p style={{color: 'red'}}>{this.props.error}</p>

                    <div className="ms-Grid-col ms-sm12 ms-lg6 ms-lgOffset6 ms-xl4 ms-xlOffset8 p-x-0">
                        <div className="big-button-container w-100">
                            <button className={buttonClass} disabled={this.state.isAwaitingResponse} onClick={handleSubmit(this.onFormSubmit.bind(this))}>
                                <div className="loader"></div>
                                <span>Send inn</span>
                            </button>
                        </div>
                    </div>

                    {/*
                    <div className="ms-Grid-row ms-textAlignRight">
                        <DefaultButton
                            primary={true}
                            disabled={this.state.isAwaitingResponse}
                            text="Send inn"
                            onClick={handleSubmit(this.onFormSubmit.bind(this))}
                        />
                    </div>
                    */}
                </div>

                {this.state.isAwaitingResponse &&
                <LoadingDialog
                    title={this.state.requestStatusText}
                    description="Vennligst vent"
                />
                }
            </form>
        )
    }
}

function validate(values:Reklamasjon){
    const errors:any = {};

    let phoneRegex = new RegExp('^\\d+$');
    let emailRegex = new RegExp(/\S+@\S+\.\S+/);

    if (!values.Title){
        errors.Title = 'Vennligst oppgi navn';
    }
    if (!values.Leilighet){
        errors.Leilighet = 'Vennligst oppgi leilighetsnummer';
    }
    if (!values.Mobil || !phoneRegex.test(values.Mobil) || values.Mobil.length < 8){
        errors.Mobil = 'Vennligst oppgi et gyldig mobilnummer';
    }
    if (!values.Mail || !emailRegex.test(values.Mail)){
        errors.Mail = 'Vennligst oppgi en gyldig mail';
    }
    if (!values.Dato_Innsendt){
        errors.Dato_Innsendt = 'Vennligst oppgi en dato';
    }
    if (!values.Emne){
        errors.Emne = 'Vennligst oppgi et emne';
    }
    if (!values.Rom){
        errors.Rom = 'Vennligst oppgi rom / plassing';
    }
    if (!values.Beskrivelse) {
        errors.Beskrivelse = 'Vennligst skriv en beskrivelse av feilen';
    }

    return errors;
}

const mapStateToProps = (state, ownProps) => {
    return{
        error: null
    }
};

export default reduxForm({
    form: 'ReklamasjonForm',
    validate,
    enableReinitialize: true,
    onSubmitFail: ()=>{
        // If form submission fails, we scroll to the top of the form
        window.scrollTo(0,0);
    },
})(connect(mapStateToProps, {createReklamasjon, uploadReklamasjonAttachment})(ReklamasjonForm));