import * as React from 'react';
import * as moment from 'moment';
import {Link, withRouter} from 'react-router-dom';
import {Reklamasjon} from "../../models/Reklamasjon";

import './Reklamasjon.scss';
import {Icon} from "office-ui-fabric-react";
import {getStatusColor} from "../../utils/ReklamasjonUtils";

interface IReklamasjonListItemProps{
    reklamasjon:Reklamasjon;
}

interface IReklamasjonListItemState{

}

class ReklamasjonListItem extends React.Component<IReklamasjonListItemProps, IReklamasjonListItemState>{

    constructor(props: Readonly<IReklamasjonListItemProps>) {
        super(props);
    }

    render(){

        const {Emne, Rom, Dato_Innsendt, Dato_Avsluttet, Status, Beskrivelse, Kommentar_Til_Kunde} = this.props.reklamasjon;

        return(
            <div className="reklamasjon-list-item ms-Grid-col ms-sm12 p-x-0">
                <div className="vertical-align p-y-10">
                    <div className="reklamasjon-subject-container ms-Grid-col ms-sm8">
                        <h3>{Emne}</h3>
                        <p>{Rom}</p>
                    </div>
                    <div className="reklamasjon-status-container ms-Grid-col ms-sm4">
                        <div className="reklamasjon-status vertical-align">
                            <div className="reklamasjon-status-text">
                                <div>Status:</div>
                                <span>{Status}</span>
                            </div>
                            <div className="reklamasjon-status-color" style={{background: getStatusColor(Status)}}>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ms-Grid-col ms-sm12 p-x-0">
                    <div className="reklamasjon-date ms-Grid-col ms-sm6 ms-md4 ms-lg3">
                        <span>Dato innsendt:</span>
                        <p>{Dato_Innsendt ? moment(Dato_Innsendt).format('DD.MM.YYYY') : '-'}</p>
                    </div>
                    <div className="reklamasjon-date ms-Grid-col ms-sm6 ms-md4 ms-lg3">
                        <span>Dato avsluttet:</span>
                        <p>{Dato_Avsluttet ? moment(Dato_Avsluttet).format('DD.MM.YYYY') : '-'}</p>
                    </div>

                    <div className="reklamasjon-date ms-Grid-col ms-sm12">
                        <span>Beskrivelse</span>
                        <p>{Beskrivelse}</p>
                    </div>

                    <div className="reklamasjon-date ms-Grid-col ms-sm12">
                        <span>Kommentar fra Ruta</span>
                        {!Kommentar_Til_Kunde && <p style={{color: 'lightgrey'}}>-</p>}
                        <p>{Kommentar_Til_Kunde}</p>
                    </div>
                </div>
                {/*
                <div className="reklamasjon-footer ms-Grid-col ms-sm12 p-x-0 ms-textAlignCenter">
                    <div className="align-center">
                        <a href="" target="_blank" className="vertical-align p-y-10">
                            <span>Vis detaljer</span>
                            <Icon iconName="ChromeBackMirrored"/>
                        </a>
                    </div>
                </div>
                */}
            </div>
        )
    }
}

export default ReklamasjonListItem;