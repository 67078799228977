import * as React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import PageViewContainer from "../common/PageViewContainer";
import PageViewHeader from "../common/PageViewHeader";
import {Prosjekt} from "../../models/Prosjekt";
import {getProsjekt} from "../../actions/Action_Prosjekt";
import {getFolderStructure} from "../../actions/Action_FDV";
import {FileItem} from "../../models/FileItem";

import './FDVView.scss';
import {Icon, IconButton} from "office-ui-fabric-react";

const fileIcons: { name: string }[] = [
    { name: 'accdb' },
    { name: 'csv' },
    { name: 'docx' },
    { name: 'dotx' },
    { name: 'mpt' },
    { name: 'odt' },
    { name: 'one' },
    { name: 'onepkg' },
    { name: 'onetoc' },
    { name: 'pptx' },
    { name: 'pub' },
    { name: 'vsdx' },
    { name: 'xls' },
    { name: 'xlsx' },
    { name: 'xsn' },
    {name: 'pdf'},
];

interface IFDVViewProps {
    prosjekt:Prosjekt;
    fileItems:FileItem[];

    history:any;

    // Requests
    getProsjekt();
    getFolderStructure();
}

interface IFDVViewState {
    isAwaitingDownload:boolean;
}

class FDVView extends React.Component<IFDVViewProps, IFDVViewState>{


    constructor(props: Readonly<IFDVViewProps>) {
        super(props);

        this.state = {
            isAwaitingDownload: false
        }
    }

    componentWillMount(){

        if (!this.props.prosjekt){
            this.props.getProsjekt().then(response=>{

            });
        }

        if (!this.props.fileItems.length){
            this.props.getFolderStructure().then(response=>{

            });
        }
    }

    getFileIcon(type:string):string{

        let iconName = '';

        if (type != 'folder'){
            fileIcons.forEach(icon=>{
                if (icon.name == type){
                    iconName = icon.name;
                }
            });
        } else{
            return 'OpenFolderHorizontal';
        }

        return iconName;
    }

    downloadZIP(){
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let file = 'https://rutaboligportal.azurewebsites.net/api/FDV?code=31Y5HfdHrqDtaZe8/NNrjhqCu1wIyYmhCRKps5sagABZaq0yYJ2zEQ==';

        let headers = new Headers();
        headers.append('Authorization', localStorage.getItem('token'));

        this.setState({isAwaitingDownload: true});

        fetch(file, { headers })
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);

                anchor.href = objectUrl;
                anchor.download = 'FDV.zip';
                anchor.click();


                this.setState({isAwaitingDownload: false});

                window.URL.revokeObjectURL(objectUrl);
            });
    }

    downloadSingleFile(fileItem:FileItem){

        console.log(fileItem);
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let file = 'https://rutaboligportal.azurewebsites.net/api/FDV/download_file?code=31Y5HfdHrqDtaZe8/NNrjhqCu1wIyYmhCRKps5sagABZaq0yYJ2zEQ==&path=' + fileItem.Path;

        let headers = new Headers();
        headers.append('Authorization', localStorage.getItem('token'));

        //this.setState({isAwaitingDownload: true});

        fetch(file, { headers, method: 'POST', body: JSON.stringify({path: fileItem.Path}) })
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);

                anchor.href = objectUrl;
                anchor.download = fileItem.Name;
                anchor.click();
                //this.setState({isAwaitingDownload: false});

                window.URL.revokeObjectURL(objectUrl);
            });
    }

    renderProsjektColumn(){

        if (this.props.prosjekt){

            const {Title, Description, Image} = this.props.prosjekt;

            return(
                <div>
                    <img style={{maxWidth: '100%'}} src={Image} />
                    <h3>{Title}</h3>
                    <p>{Description}</p>
                </div>
            )
        }
    }

    renderFolderStructure(fileItems:FileItem[]){

        if (fileItems.length){
            return fileItems.map(item=>{

                const fileItemClassName = `file-item vertical-align ${item.FileType == 'folder' ? 'folder':''}`;

                return(
                    <div>
                        <div className={fileItemClassName}>
                            <Icon iconName={this.getFileIcon(item.FileType)} />
                            <span>{item.Name}</span>

                            {item.FileType != 'folder' &&
                                <div className="file-item-download">
                                    <IconButton
                                        iconProps={{ iconName: 'CloudDownload' }}
                                        title="CloudDownload" ariaLabel="CloudDownload"
                                        onClick={()=>this.downloadSingleFile(item)}
                                    />
                                </div>
                            }
                        </div>
                        {item.Children.length > 0 &&
                            <div style={{paddingLeft: '7px'}}>
                                {this.renderFolderStructure(item.Children)}
                            </div>
                        }
                    </div>
                )
            })
        }
    }

    render(){

        const buttonClass = `big-button--inverse ${(this.state.isAwaitingDownload) ? 'disabled':''}`;

        return(
            <PageViewContainer>
                <PageViewHeader title="FDV - Forvaltning, Drift og Vedlikehold" showBackButton={true} onBackButtonClicked={()=>this.props.history.push('/min-side')}/>
                <div className="ms-Grid-col ms-sm12 ms-lg4 ms-hiddenSm">
                    {this.renderProsjektColumn()}
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg8">
                    <p style={{marginTop: '0px'}}>
                        Her ligger dokumentasjon som omhandler forvaltning, drift og vedlikehold av din bolig.
                        Vi anbefaler at du du laster ned denne dokumentasjonen og legger den inn på din private boligmappe, <a href="https://www.boligmappa.no/" target="_blank">www.boligmappa.no</a>.
                        Da vil FDV-dokumentasjonen for din bolig være lett tilgjengelig også etter reklamasjonsperiodens utløp på 5 år.
                    </p>
                    <p>
                        Du kan enkelt laste ned all dokumentasjon i en samlet Zip – Fil som kan lagres elektronisk eller printes ut.
                    </p>

                    <div className="big-button-container p-y-20">
                        <button className={buttonClass} disabled={this.state.isAwaitingDownload} onClick={()=>this.downloadZIP()}>
                            <div className="loader"></div>
                            <span>{this.state.isAwaitingDownload ? 'Laster ned ':'Last ned alle dokumenter'}</span>
                        </button>
                    </div>

                    {this.renderFolderStructure(this.props.fileItems)}

                </div>
            </PageViewContainer>
        )
    }
}

const mapStateToProps = (state, ownProps:IFDVViewProps) => {
    return{
        fileItems: state.FDV.files,
        prosjekt: state.prosjekt.prosjekt
    }
};

export default withRouter(connect(mapStateToProps,{getProsjekt, getFolderStructure})(FDVView));