import * as React from 'react';
import {Dialog, ProgressIndicator} from "office-ui-fabric-react";


export interface ILoadingDialogProps{
    title:string;
    description:string;
}


export class LoadingDialog extends React.Component<ILoadingDialogProps>{

    constructor(props){
        super(props);
    }

    render(){
        return(
            <Dialog
                hidden={false}
                styles={{
                    main: [{
                        selectors: {
                            ['@media (min-width: 480px)']: {
                                maxWidth: '600px',
                                minWidth: '480px'
                            }
                        }
                    }]
                }}
                modalProps={{
                    isBlocking: true,
                    containerClassName: 'ms-dialogMainOverride'
                }}
            >
                <ProgressIndicator label={this.props.title} description={this.props.description} />
            </Dialog>
        )
    }
}