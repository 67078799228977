import {FileItem} from "../models/FileItem";
import {GET_FDV_STRUCTURE, RESET_FDV_STATE} from "../actions/Action_FDV";

interface IFDVState {
    files:FileItem[];
}

const initialFDVState:IFDVState = {
    files: []
};

export default (state = initialFDVState, action) => {

    switch (action.type){

        case GET_FDV_STRUCTURE:

            return {...state, files: action.payload};

        case RESET_FDV_STATE:

            return initialFDVState;

        default:
            return state;
    }
}